import React from "react";
import clsx from "clsx";

import { Asset, Decorator, RichText } from "components";
import { Translation } from "./_Translation";

function Hero({
  heroContent,
  heroDecorators,
  heroHeading,
  heroImage,
  translatedHeroContent,
  slug,
}) {
  if (heroHeading)
    return (
      <header className="relative dark">
        <div className={"dark:bg-darkPlum pt-32 pb-12 -mt-32 lg:pb-32"}>
          <div className="relative pt-10 pb-12 wrapper md:pt-24 default-grid">
            {heroDecorators && heroDecorators[0] && (
              <Decorator
                className="absolute hidden -translate-x-full top-4 sm:left-fluidMargin xl:left-26 md:block"
                asset={heroDecorators && heroDecorators[0].asset}
                size="small"
              />
            )}
            <div className="space-y-8 col-span-full md:col-span-8">
              <div className="space-y-6 ">
                {heroHeading && (
                  <h1 className="h1 text-darkPurple dark:text-thistle">
                    {heroHeading}
                  </h1>
                )}
                {heroContent && (
                  <RichText
                    className="max-w-3xl"
                    content={heroContent}
                    size="large"
                  />
                )}
                {translatedHeroContent && (
                  <Translation translatedHeroContent={translatedHeroContent} />
                )}
              </div>
              {/* <div>
            <div className="flex flex-wrap items-center -m-3 md:-m-4 md:flex-nowrap">
              {heroButtons && heroButtons[0]?.link?.link && (
                <div className="p-3 md:p-4">
                  <Button
                    label={heroButtons[0]?.label}
                    href={heroButtons[0]?.link?.link}
                  />
                </div>
              )}
              {heroButtons && heroButtons[1]?.link?.link && (
                <div className="p-3 md:p-4">
                  <IconButton
                    label={heroButtons[1]?.label}
                    href={heroButtons[1]?.link?.link}
                  />
                </div>
              )}
            </div>
          </div> */}
            </div>
            <div
              className={clsx(
                "self-center col-span-full md:col-span-3 md:col-start-10",
                heroImage ? "" : "hidden md:block"
              )}
            >
              <div className="-mx-6 sm:-mx-fluidMargin md:ml-0 xl:-mr-26">
                <div className="select-none aspect-w-1 aspect-h-1">
                  {heroImage && (
                    <Asset
                      asset={heroImage}
                      objectFit="cover"
                      objectPosition="center"
                      style={{ position: "absolute" }}
                    />
                  )}
                  {/* {heroVideo && (
                <LazyLoad
                  classNamePrefix="absolute top-0 bottom-0 left-0 right-0 w-full h-full"
                  once
                >
                  <Asset
                    className="absolute top-0 bottom-0 left-0 right-0 object-contain object-center w-full h-full"
                    asset={heroVideo}
                  />
                </LazyLoad>
              )} */}
                  <div>
                    {heroImage && heroDecorators && heroDecorators[1] && (
                      <div className="absolute left-0 hidden w-40 h-40 transform translate-y-1/2 select-none md:block -translate-x-7 md:-translate-x-1/2 bottom-4 md:left-4 lg:w-44 lg:h-44">
                        <Decorator
                          asset={heroDecorators && heroDecorators[1].asset}
                          size="small"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!heroImage && heroDecorators && heroDecorators[1] && (
              <Decorator
                className="absolute right-0 hidden md:block bottom-24"
                asset={heroDecorators && heroDecorators[1].asset}
                size="large"
              />
            )}
          </div>
        </div>
      </header>
    );
  else return null;
}

export default Hero;
