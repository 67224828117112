import React, { useState } from "react";
import { DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
import { AnimatePresence, createDomMotionComponent } from "framer-motion";

import { Icon, RichText } from "components";

const MotionDialogOverlay = createDomMotionComponent(DialogOverlay);
const MotionDialogContent = createDomMotionComponent(DialogContent);

export function Translation({ translatedHeroContent }) {
  const labelId = `translation-dialog`;

  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <>
      <button className="flex items-center justify-center" onClick={open}>
        <span className="text-link dark:text-thistle">View in English</span>
      </button>

      <AnimatePresence>
        {showDialog && (
          <MotionDialogOverlay
            className="flex z-dialog md:px-26 md:py-14"
            isOpen={showDialog}
            onDismiss={close}
            style={{ backgroundColor: "rgba(0, 0, 0, 0.25)" }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <MotionDialogContent
              aria-labelledby={labelId}
              className="bg-white"
              style={{
                width: "100%",
                maxWidth: 810,
                margin: "auto",
                padding: 0,
                boxShadow: "0px 30px 40px rgba(0, 0, 0, 0.25)",
              }}
              initial={{ y: 32 }}
              animate={{ y: 0 }}
              exit={{ y: -32 }}
              transition={{ type: "spring", bounce: 0.25, duration: 0.25 }}
            >
              <div className="relative px-12 py-24 md:px-14">
                <div className="absolute top-4 right-4 md:top-10 md:right-10">
                  <button
                    className="flex items-center justify-center w-11 h-11 focus:outline-none"
                    onClick={close}
                  >
                    <Icon className="text-violet" symbol="times" size={18} />
                    <span className="sr-only">Close</span>
                  </button>
                </div>

                <div className="lg:space-y-6 space-y-14">
                  <div className="flex flex-col items-center">
                    {translatedHeroContent && (
                      <RichText
                        className="max-w-3xl"
                        content={translatedHeroContent}
                        size="large"
                      />
                    )}
                  </div>
                </div>
              </div>
            </MotionDialogContent>
          </MotionDialogOverlay>
        )}
      </AnimatePresence>
    </>
  );
}
